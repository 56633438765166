import {
    ToggleActionTypes,
    CLEAR_TOGGLES_LOADED,
    SET_TOGGLES,
    SET_TOGGLES_LOADED,
} from "../types/ToggleActionTypes";
import {IToggle} from "../types";

export interface IToggleState {
    toggles: IToggle[];
    showBanner: boolean;
    showDashboard: boolean;
    allowBulletinScheduling: boolean;
    underConstruction: boolean;
    togglesLoaded: boolean;
    showFHBCBaseCapability: boolean;
    error: string;
    bannerMessage: string
}

export const initialState: IToggleState = {
    toggles: [],
    showBanner: false,
    showDashboard: false,
    allowBulletinScheduling: false,
    underConstruction: false,
    togglesLoaded: false,
    showFHBCBaseCapability: false,
    error: "",
    bannerMessage:""
};

export function toggleReducer(state: IToggleState = initialState, action: ToggleActionTypes): IToggleState {
    if (state === undefined) {
        return state;
    }

    switch (action.type) {
        case SET_TOGGLES:
            return {
                ...state,
                toggles: action.toggles,
                showBanner: getToggleValue("banner", action.toggles),
                showDashboard: getToggleValue("dashboard", action.toggles),
                allowBulletinScheduling: getToggleValue("scheduling", action.toggles),
                underConstruction: getToggleValue("underConstruction", action.toggles),
                showFHBCBaseCapability: getToggleValue("showFHBCBaseCapability", action.toggles),
                togglesLoaded: true,
                bannerMessage: getToggleBannerValue("banner",action.toggles),

            };
        case SET_TOGGLES_LOADED:
            return {...state, togglesLoaded: action.loaded};
        case CLEAR_TOGGLES_LOADED:
            return {...state, togglesLoaded: false};
        default:
            return state;
    }
}

function getToggleValue(toggleName: string, toggles: IToggle[]): boolean {
    const toggle = toggles.filter(t => t.name === toggleName);
    if (toggle.length <= 0){
        return false;
    }

    return toggle[0].on;
}
function getToggleBannerValue(toggleName: string, toggles: IToggle[]):string {
    const toggle = toggles.filter(t => t.name === toggleName);
    if (toggle.length >= 0){
        return toggle[0].bannerMessage;
    }
    return "";

}