import React from 'react';
import * as S from "../styles";
import {useHistory} from "react-router-dom";
import downloadIcon from "../icons/download_charcoal.svg";
import printIcon from "../icons/print_option.svg";
import expandAllIcon from "../icons/expand_charcoal.svg";
import collapseAllIcon from "../icons/collapse_charcoal.svg";
import useTypedSelector from "../hooks/useTypedSelector";
import {OutageDisplay} from "../types";
import {locale} from "../locale";
import {useDispatch} from "react-redux";
import {FileDownloadUtilities} from "../utilities/FileDownloadUtilities";
import {setOutageDisplay} from "../actions/outagesActions";
import { VolumeUnits } from "../types";
import { getDGSRPrintHeader, getGDSRPrintFooter, getPrintUnsupportedFF } from './GasDaySummaryReport/GasDaySummaryPrintHelperFuntions';
import DateUtilities from '../utilities/DateUtilities';

export class MoreOptionsItems {
    static DownloadChartData: MoreOptionsItem = {nameKey: "chart.data", icon: downloadIcon, action: "downloadChartData"};
    static DownloadOutages: MoreOptionsItem = {nameKey: "CSV", icon: downloadIcon, action: "downloadOutages"};
    static ViewAllOutages: MoreOptionsItem = {nameKey: "expand", icon: expandAllIcon, link: "/OutagesList/"};
    static CollapseAllOutages: MoreOptionsItem = {nameKey: "collapse", icon: collapseAllIcon, action: "navigateToSummariesView", link: "/"};
    static DownloadPlantTurnAround: MoreOptionsItem = {nameKey: "plantTurnaroundDownloadCsvHeaderLink", icon: downloadIcon, action: "downloadPlantTurnAround"};
    static DownloadCurrentSystemReport: MoreOptionsItem = {nameKey: "CSV", icon: downloadIcon, action: "downloadCurrentSystemReportData"};
    static DownloadGasDaySummaryReport: MoreOptionsItem = {nameKey: "CSV", icon: downloadIcon, action: "downloadGasDaySummaryReportData"};
    static PrintGasDaySummaryReport: MoreOptionsItem = {nameKey: "Print", icon: printIcon, action: "printGasDaySummaryReport"};
}

interface MoreOptionsItem {
    icon: string;
    link?: string;
    nameKey: string;
    action?: string;
}

interface MoreOptionsProperties {
    isVisible: boolean;
    options: MoreOptionsItem[];
    selectedDate?: Date | undefined;
}

const MoreOptions: React.FC<MoreOptionsProperties> = (props) => {
    const publishDate = useTypedSelector(x => x.outages.publishDate);
    const unit = useTypedSelector(x => x.app.volumeUnit)
    const duration = useTypedSelector(x => x.currentSystemReport.duration)
    const history = useHistory();
    const dispatch = useDispatch();

    const formatDate = (date: Date): string => {
        return DateUtilities.ServiceDateOnlyUtility.Reformat(date.toString());
    }

    const actions: { [id: string]: () => void } = {
        downloadOutages: function () {
            FileDownloadUtilities.initiateCsvFileDownload(`csv/outages/${publishDate}`, dispatch);
        },
        downloadChartData: function () {
            FileDownloadUtilities.initiateCsvFileDownload(`chart/csv`, dispatch);
        },
        navigateToSummariesView: function () {
            dispatch(setOutageDisplay(OutageDisplay.List));
        },
        downloadPlantTurnAround: function () {
            FileDownloadUtilities.initiateCsvFileDownload(`plantturnaroundactivity/csv/${publishDate}`, dispatch);
        },
        downloadCurrentSystemReportData: function () {
            const volumeUnit = unit === VolumeUnits.THOUSAND_CUBIC_METERS ? "10%5E3M%5E3" : "MMcf"
            FileDownloadUtilities.initiateCsvFileDownload(`csr/csv/?unit=${volumeUnit}&duration=${duration}`, dispatch)
        },
        downloadGasDaySummaryReportData: function () {
            const volumeUnit = unit === VolumeUnits.MILLION_CUBIC_METERS ? "10%5E6M%5E3" : "MMcf"
            const selectedDate = props.selectedDate ? props.selectedDate : undefined;
            const offsetHours = selectedDate ? selectedDate.getTimezoneOffset() / 60 : undefined ;
            const selectedHour = selectedDate ? selectedDate.getHours() : undefined ;
            if(offsetHours && selectedDate !== undefined && selectedHour !== undefined){
                if(offsetHours  >0 && selectedHour !=0){
                    selectedDate.setDate(selectedDate.getDate() + 1);
                }
            }
            const formated_selectedDate = selectedDate ? formatDate(selectedDate) : undefined;
            selectedDate ? FileDownloadUtilities.initiateCsvFileDownload(`gdsr/csv/?unit=${volumeUnit}&date=${formated_selectedDate}`, dispatch) :
                FileDownloadUtilities.initiateCsvFileDownload(`gdsr/csv/?unit=${volumeUnit}`, dispatch);
        },
        printGasDaySummaryReport: function() {
            const isFirefox = navigator.userAgent.toLowerCase().indexOf('firefox') > -1;
            const specific_element = document.getElementById("GDSRBigTableContainer")?.outerHTML;
            const original_elements = document.body.innerHTML;

            const selectedDate = props.selectedDate ? props.selectedDate : undefined;
            const offsetHours = selectedDate ? selectedDate.getTimezoneOffset() / 60 : undefined ;
            const selectedHour = selectedDate ? selectedDate.getHours() : undefined ;
            if(offsetHours && selectedDate !== undefined && selectedHour !== undefined){
                if(offsetHours  >0 && selectedHour !=0){
                    selectedDate.setDate(selectedDate.getDate() + 1);
                }
            }
            const date = DateUtilities.formatDate(selectedDate ? selectedDate : DateUtilities.getPreviousGasDay(new Date(), -3));
            if (specific_element){
                document.body.innerHTML = isFirefox ? getPrintUnsupportedFF() : getDGSRPrintHeader(unit, date) + specific_element;
                window.print();
                document.body.innerHTML = original_elements; // commentting reload functionality
                window.location.reload();
            }
        }
    };

    const handleClick = function (option: MoreOptionsItem) {
        console.log(option.action)
        if (option.action && actions[option.action]) {
            actions[option.action]();
        }
        if (option.link) {
            history.push(option.link);
        }
    };

    return (
        <S.MoreOptions key={'more-options'} style={{marginRight: "15px"}} onClick={e => e.stopPropagation()}>
            {props.options.map((option) =>
                <S.MoreOptionsItem key={option.nameKey} style={{height: "32px"}} onClick={() => handleClick(option)}>
                    <S.MoreOptionsIcon width={16} height={16} src={option.icon}
                            alt={option.nameKey}/>
                    <S.MoreOptionsLabel>{locale(option.nameKey)}</S.MoreOptionsLabel>
                </S.MoreOptionsItem>
            )}
        </S.MoreOptions>
    );
};

export default MoreOptions;