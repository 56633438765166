import {LatLngBoundsExpression, LatLngTuple} from "leaflet";
import {IAreaOutagesSummary} from "./AreaOutagesSummary";
import {IValidationResult} from "../utilities/validation/Validation";
import {EditorState} from "react-draft-wysiwyg";

export enum Status {
    NEW = 'new',
    CHANGED = 'changed',
    REMOVED = 'removed',
    UNCHANGED = 'unchanged',
}

export enum Chips {
    TSP_ELIGIBLE = 'tspEligible',
    PARTIAL_IMPACT = 'partialImpact',
    POTENTIAL_IMPACT = 'potentialImpact',
}

export enum PublicationStatus {
    DRAFT = 'Draft',
    SENT = 'Sent',
    SCHEDULED = 'Scheduled',
}

export enum StationListStatus {
    APPROVED = 'Approved',
    EXCLUDE = 'Exclude',
    NEW = 'New'
}

export enum VolumeUnits {
    THOUSAND_CUBIC_METERS = "10\u00b3m\u00b3/day",
    MILLION_CUBIC_METERS = "10\u2076m\u00b3/day",
    MILLION_CUBIC_FEET = "MMcf/day",
    BILLION_CUBIC_FEET = "Bcf/day"
}

//this Enum is mainly used for options in Displayed Values Control
export enum CurrentSystemReportVolumeUnits {
    THOUSAND_CUBIC_METERS = "10\u00b3m\u00b3/day",
    MILLION_CUBIC_FEET = "MMcf/day",
}

export enum CurrentSystemReportDataGridTypes {
    RECEIPTS = 'RECEIPTS',
    DELIVERY = "DELIVERY",
    LINEPACK = "LINEPACK"
}

export enum EnergyUnits {
    TERAJOULE = "TJ/day",
    GIGAJOULE = "GJ/day",
    DEKATHERM_OR_MILLION_BRITISH_THERMAL_UNITS = "Dth or MMBtu/day"
}

export enum GasDaySummaryReportVolumeUnits {
    MILLION_CUBIC_METERS = "10\u2076m\u00b3/day",
    MILLION_CUBIC_FEET = "MMcf/day",
}

export enum CurrentSystemReportEnum{
    ALBERTABORDERFLOW = 'albertaBorderFlow',
    WILLOWVALLEYINTERCONNECT ='willowValleyInterconnect',
    CURRENTLINEPACK='currentLinepack',
    EMPRESSBORDERFLOW='empressBorderFlow',
    FIELDRECEIPTS='fieldReceipts',
    FLOWDIFFERENTIAL='flowDifferential',
    GORDONDALERECEIPT='gordondaleReceipt',
    GROUNDBIRCHEASTRECEIPT='groundBirchEastReceipt',
    INTRAPROVINCIALDEMAND='intraprovincialDemand',
    LASTUPDATED='lastUpdated',
    LINEPACKTARGET='linepackTarget',
    LINEPACK4HR='linepack4Hr',
    MCNEILBORDERFLOW='mcneilBorderFlow',
    NETSTORAGEFLOW='netStorageFlow',
    TOTALDELIVERIES='totalDeliveries',
    TOTALRECEIPTS='totalReceipts'
}

export enum CurrentSystemReportColorEnum{
    ALBERTABORDERFLOW = '#B2282E',
    WILLOWVALLEYINTERCONNECT = '#799B3E',
    CURRENTLINEPACK = '#237CC1',
    EMPRESSBORDERFLOW = '#53C0D5',
    FIELDRECEIPTS = '#EE5E87',
    FLOWDIFFERENTIAL = '#96279A',
    GORDONDALERECEIPT = '#53C0D5',
    GROUNDBIRCHEASTRECEIPT = '#799B3E',
    INTRAPROVINCIALDEMAND = '#F7842B',
    LINEPACKTARGET='#F7842B',
    LINEPACK4HR = '#468B45',
    MCNEILBORDERFLOW = '#EE5E87',
    NETSTORAGEFLOW = '#000',
    TOTALDELIVERIES = '#468B45',
    TOTALRECEIPTS = '#F7842B'
}


export const ENERGY_CONVERSION_FACTOR = 1.0551;

export const INVALID = 'N/A';

export const AdminDailyOperationPlanColumnWidths = {
    outageId: '80',
    description: '100',
    impact: '100',
    start: '95',
    end: '95',
    capability: '87',
    typicalFlow: '120',
    notes: '300',
    unit: '50',
    addRow: '20',
    removeRow: '20'
};

export interface IDopOutage {
    outageid: number;
    impactid: number;
    analysistype: string;
    analysisstatus: string;
    simulationname: string;
    areaofimpact: string;
    comments: string;
    typicalflow: string;
    flowcapability: string;
    areabasecapability: string;
    areaoutagecapability: string;
    linepack: number;
    linepacksegment: string;
    userstatus: string;
    impactstartdatetime: string;
    impactenddatetime: string;
    outagedescription: string;
}

export interface IFieldDirtyDictionary {
    [key: string]: boolean;
}

export interface IOutageBase {
    startDateTime: string;
    endDateTime: string;
    area: IArea;
    flowCapability: string;
}

export interface IOutageForChart {
    startDateTime: string;
    endDateTime: string;
    flowCapability: string;
    area: IArea;
}

export interface IOutageForDateSort {
    startDateTime: string;
    endDateTime: string;
}

export interface ICurrentSystemReportDataFromDatabase {
    [key: string]: number;
}

export interface IGasDayChangesDictionary {
    gasDay: Date;
    storageReceipts: number;
    netStorage: number;
    totalDeliveries: number;
    totalSDAccount: number;
    linepackVariance: number;
    toleranceMin: number;
    toleranceMax: number;
    difference: IGasDayChangesDifferencesDictionary;
}

export interface IGasDayChangesDifferencesDictionary {
    storageReceipts: number;
    netStorage: number;
    totalDeliveries: number;
    totalSDAccount: number;
    targetLinepack: number;
    toleranceMin: number;
    toleranceMax: number;
}

export interface ICurrentSystemReportChartData {
    albertaBorderFlow: number;
    willowValleyInterconnect: number;
    currentLinepack: number;
    empressBorderFlow: number;
    fieldReceipts: number;
    flowDifferential: number;
    gordondaleReceipt: number;
    groundBirchEastReceipt: number;
    intraprovincialDemand: number;
    lastUpdated: Date | undefined;
    linepackTarget: number;
    linepack4Hr: number;
    mcneilBorderFlow: number;
    netStorageFlow: number;
    totalDeliveries: number;
    totalReceipts: number;
}

export interface ICurrentSystemReportActive {
    albertaBorderFlow: boolean;
    willowValleyInterconnect: boolean;
    currentLinepack: boolean;
    empressBorderFlow: boolean;
    fieldReceipts: boolean;
    flowDifferential: boolean;
    gordondaleReceipt: boolean;
    groundBirchEastReceipt: boolean;
    intraprovincialDemand: boolean;
    linepackTarget: boolean;
    linepack4Hr: boolean;
    mcneilBorderFlow: boolean;
    netStorageFlow: boolean;
    totalDeliveries: boolean;
    totalReceipts: boolean;
    [key: string]: boolean;
}

export interface ICurrentSystemReportCheckbox {
    value: string;
    checked: boolean;
    disabled: boolean;
}

export interface IOutage extends IOutageBase {
    id: number;
    outageId: number;
    impactId: number;
    areaId: number;
    impact: string;
    typicalFlow: string;
    flowCapability: string;
    localAreaBaseCapability: string;
    localAreaOutageCapability: string;
    description: string;
    publishedDateTimeUtc: string;
    areaBaseCapability?: string;
}

export interface IHasValidationResults {
    validationResults: IValidationDictionary;
}

export interface IOutageForSave {
    startDateTime: string;
    endDateTime: string;
    outageId: number;
    impactId: number;
    areaId: number;
    impact: string;
    typicalFlow: string;
    flowCapability: string;
    localAreaBaseCapability: string;
    localAreaOutageCapability: string;
    description: string;
}

export interface IOutageForEdit extends IOutageBase, IHasValidationResults {
    id: number;
    outageId: string;
    impactId: number;
    impact: string;
    typicalFlow: string;
    flowCapability: string;
    localAreaBaseCapability: string;
    localAreaOutageCapability: string;
    description: string;
    hasFieldDirty: IFieldDirtyDictionary;
    temporaryId: number;
}

export interface IValidationDictionary {
    [key: string]: IValidationResult;
}

export type Payload<T> = {
    message: string;
    data: T;
    error?: boolean;
    loading: boolean;
}

export interface IOutageGroups {
    [key: string]: IAreaOutagesSummary;
}

export enum OutageDisplay {
    Map = 1,
    List,
    Graph,
}

//Map
export interface IAreaHistory {
    id: number;
    areaId: number;
    notes: string;
}

export interface IAreaHistoryForSave {
    areaId: number;
    notes: string;
}

export interface IArea {
    id: number;
    acronym: string;
    dopAcronym: string;
    displayName: string;
    typeName: string;
    centerLat: number;
    centerLng: number;
    offsetLat: number;
    offsetLng: number;
    locations: ILocation[];
    color: string;
    notes: string;
    sortOrder: number;
    hasTypicalFlow: boolean;
    hasLocalOutages: boolean;
    isPlantTurnAround: boolean;
    capabilities: ICapability[];
    capabilityAssumptionsHeader: string;
    capabilityAssumptions: string;
}

export interface IAreaForEdit extends IArea, IHasValidationResults {
    hasFieldDirty: IFieldDirtyDictionary;
    validationResults: IValidationDictionary;
}

export interface ICapabilityBase {
    id: number;
    areaId: number;
    startDate: string;
    endDate: string;
}

export interface ICapability extends ICapabilityBase {
    capability: number;
}

export interface ICapabilityForEdit extends ICapabilityBase, IHasValidationResults {
    hasFieldDirty: IFieldDirtyDictionary;
    validationResults: IValidationDictionary;
    capability: string;
    allowDelete: boolean;
}

export interface ILocation {
    id: number;
    lat: number;
    lng: number;
}

export interface IMapData {
    mapCenter: LatLngTuple;
    minZoom: number;
    maxZoom: number;
    zoom: number;
    maxBounds: LatLngBoundsExpression;
}

export interface IOutageComparison extends IOutage {
    changeStatus: Status;
    current: IOutage | null;
    previous: IOutage | null;
    readonly hasDescriptionChanged: boolean;
    readonly hasEndDateTimeChanged: boolean;
    readonly hasFlowCapabilityChanged: boolean;
    readonly hasLocalAreaBaseCapabilityChanged: boolean;
    readonly hasLocalAreaOutageCapabilityChanged: boolean;
    readonly hasImpactChanged: boolean;
    readonly hasStartDateTimeChanged: boolean;
    readonly hasTypicalFlowChanged: boolean;
    readonly hasOutageIdChanged: boolean;
    readonly hasAreaBaseCapabilityChanged: boolean;
}

// chart
export interface IChartData {
    day: string;
    value: number;
    previousValue?: number;
    local?: number;
    area: string;
    flow?: number;
    historicalDay?: string;
    historicalFlow?: number;
    contract?: number;
}

export interface IShowingChartKeys {
    key: string;
    order: number;
}

export interface IChartTooltip {
    active: boolean;
    label: string;
    payload: IChartPayload[];
}

export interface IChartPayload {
    name: string;
    value: number;
    stroke: string;
    payload?: ICurrentSystemReportChartData;
}

export interface IDailyCapability {
    areaId: number;
    gasDay: string;
    capabilityVolume: number;
}

export interface IDailyContract {
    areaId: number;
    gasDay: string;
    firmContractEnergy: number;
}

export interface IDailyFlow {
    areaId: number;
    gasDay: string;
    flowVolume: number;
}

export interface IDailyChartData {
    areaId: number;
    gasDay: string;
    historicalGasDay: string;
    flow?: number;
    historicalFlow?: number;
    firmContractEnergy?: number;
    capabilityVolume?: number;
}

export interface IBulletin {
    id: number | null;
    isCritical: boolean;
    subject: string;
    contactName: string;
    contactPhone: string;
    startDateTimeUtc: string | null;
    body: string;
    editorState: string;
    subscriptionId: number;
    serviceProvider: ILookupValueForEdit | null;
    category: ILookupValueForEdit | null;
    publishedBy: string;
    subscription: ISubscription | null;
    isDraft: boolean;
    lastSavedDateTimeUtc: string;
    publishedDateTimeUtc: string | null;
    scheduleDateTimeUtc: string | null;
}

export interface IBulletinForEdit extends IBulletin, IHasValidationResults {
    editor: IEditorState;
    hasFieldDirty: IFieldDirtyDictionary;
    validationResults: IValidationDictionary;
    serviceProvider: ILookupValueForEdit;
    category: ILookupValueForEdit;
}

export interface ICurrentSystemReportData {
    id: CurrentSystemReportEnum;
    category: CurrentSystemReportDataGridTypes;
    color: string;
    description: string;
    currentValue: string;
}

export interface IEditorState {
    editorState: EditorState;
    isHtml: boolean;
}

export interface ILookupValueForEdit {
    name: string;
    id: string;
}

export interface ILookupValue {
    name: string;
    id: number;
}

export interface IMainlineDailyCapability {
    gasDay: string;
    lastUpdatedDateTimeUtc: string;
    heatingValue: number;
    prairiesLineCapability: number;
    emersonExtensionCapability: number;
    northernOntarioLineCapability: number;
    easternDeliveryAreaCapability: number;
    downstreamStation148Capability: number;
}

export interface IMainlineDailyCapabilityPublication {
    heatingValue: number;
    capacityPercentage: number;
    primaryDisclaimer: string;
    secondaryDisclaimer: string;
}

export interface ITolerance {
    id: number;
    tolerance: string;
    lastUpdatedDateTimeUtc: string;
}

export interface IDictionary<T> {
    [key: string]: T;
}

export interface ISubscriptionEdit{
    editChecked: boolean;
}

export interface ISubscription extends ISubscriptionEdit {
    id: number;
    serviceProviderId: number;
    subscriptionCategoryId: number;
    name: string;
    sortOrder: number;
    isActive: boolean;
    serviceProvider: IServiceProvider;
    subscriptionCategory: ISubscriptionCategory;
}

export interface ISubscriptionCategory extends ISubscriptionEdit {
    id: number;
    name: string;
    sortOrder: number;
}

export interface IServiceProvider extends ISubscriptionEdit {
    id: number;
    name: string;
    isActive: boolean;
    sortOrder: number;
    isCollapsed: boolean;
}

export interface ISubscriberSubscription {
    subscriptionId: number;
    subscriber: ISubscriber;
}

export interface ISubscriber {
    email: string;
}

export interface ISubscriberPayload {
    subscriber: ISubscriber;
    subscriber_subscriptions: ISubscriberSubscription[];
}

export interface IStationIntegration {
    id: number;
    areaId: number;
    number: number;
    isApproved: boolean;
}

export interface IStationIntegrationForEdit extends IStationIntegration, IHasValidationResults{
    currentStatus: StationListStatus;
    hasFieldDirty: IFieldDirtyDictionary;
    validationResults: IValidationDictionary;
    area: string;
    isVisible: boolean;
}

export interface IToggle {
    id: number;
    name: string;
    on: boolean;
    bannerMessage: string;

}

export interface IBannerProps {
    hasBanner?: boolean;
    hasStickyControls?: boolean;
}

export enum SORT_ORDER {
    ACCENDING,
    DECENDING
}

export interface ColumnSort {
    columnName: string;
    sortOrder: SORT_ORDER;
}

export enum AppStatus {
    RUNNING = "Service is up and running",
    DOWN = "An error has occurred"
}

export enum SeverityTypes {
    CRITICAL = "CRITICAL",
    NON_CRITICAL = "NON-CRITICAL",
    ALL = ""
}

export type Severity = SeverityTypes.CRITICAL | SeverityTypes.NON_CRITICAL | SeverityTypes.ALL;

export enum SearchKeyTypes {
    ADMIN = "admin",
    PUBLIC = "public"
}

export interface IHeatValue {
    areaId: number;
    value: number;
    startDateTimeUtc: string;
    endDateTimeUtc: string | null;
    acronym: string;
    sortOrder: number;
    updatedDateTimeUtc: string;
    scheduledBy: string;
}

export interface IHeatValueForEdit extends IHeatValue {
    isDirty: boolean;
    validationResults: IValidationDictionary;
}

export interface IExportChartDataForm extends IHasValidationResults {
    startDate: string;
    endDate: string;
    validationResults: IValidationDictionary;
}

export type SearchKey = SearchKeyTypes.ADMIN | SearchKeyTypes.PUBLIC;