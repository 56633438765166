import styled from "styled-components";
import {device} from "./DeviceSizes";
import colors from "./Colors";

export const GlobalBanner = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;   
  flex-direction: column;  
  position: fixed;
  top: 75px; 
  z-index: 999;
  width: 100%;
  min-height: 32px;
  background: rgb(0, 0, 128, 0.85);
  font-family: Roboto, sans-serif;
  color: black;
      
  @media ${device.tablet} {
    top: 75px;
  }
  
  
  @media ${device.mobile} {
    top: 56px;
  }
  
  @media print {
    display: none;
    opacity: 0; // For printing on Firefox
  }
  
  p{     
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 11px;
    line-height: 14px;  
    color: #FFFFFF;
    margin-top: auto;    
    margin-bottom: auto;
    margin-left: 5px;
    margin-right: 5px;
    @media ${device.tablet} {
        margin: 5px;
        width: 90%;
     }
    @media ${device.mobile} {
        margin: 5px;
        width: 85%;
     }
  }
  
  a{    
      color: ${colors.tc_blue};
      margin: 0 0 16px 0;
      text-decoration: none;
      cursor: pointer;
      &:visited {
        color: ${colors.tc_blue};
      }
      &:hover, span:hover {
        text-decoration: underline;
      }
  }
`;

export const GlobalBannerChildWrapper = styled.div`
  display: flex;    
  flex-direction: row;
  flex-wrap: wrap;  
  align-items: center;
  @media ${device.desktop} {
    margin-left: -97px;
  }
`;